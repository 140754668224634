declare global {
  interface Window {
    assets: {
      appLogo?: string;
      emptyChatImage?: string;
      emptyMyConnectionsImage?: string;
      emptyMyMinglesImage?: string;
      emptyMyScheduleImage?: string;
      emptySearchResultsImage?: string;
      httpNotFoundImage?: string;
      iconSvg?: string;
      logoFooter?: string;
      logoPrimary?: string;
      mainSponsorLogo?: string;
      mingleDisabledImage?: string;
      mingleImage?: string;
      mingleSponsoredImage?: string;
      mingleVipImage?: string;
      onboardingCoverImage?: string;
      roomFullImage?: string;
      roomHoldingScreenGeneric?: string;
      roomHoldingScreenMasterclass?: string;
      roomHoldingScreenQa?: string;
      roomReactionsSprite?: string;
      secondarySponsorLogo?: string;
    };
  }
}

export const Asset = window.assets ?? {};
