import algoliasearch from 'algoliasearch';
import { ReactElement,ReactNode } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';

import { search } from '~/lib/analytics';
import type { SearchConfig } from '~/types/search';

const AlgolaSearchProvider = ({
  appId,
  searchAPIKey,
  replicaName,
  filters,
  facetFilters,
  facets,
  hitsPerPage,
  children,
}: SearchConfig & { children: ReactNode }): ReactElement<SearchConfig> => {
  const client = algoliasearch(appId, searchAPIKey);

  return (
    <InstantSearch
      indexName={replicaName}
      searchClient={client}
      onSearchStateChange={(params) => {
        if (params.query) {
          search(params.query);
        }
      }}
    >
      <Configure
        facetFilters={facetFilters}
        facets={facets}
        filters={filters}
        hitsPerPage={hitsPerPage}
      />
      {children}
    </InstantSearch>
  );
};

export default AlgolaSearchProvider;
