import anime from 'animejs';
import clsx from 'clsx';
import { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export type ModalTriggerArguments = {
  openModal: () => void;
};

type ChildrenArguments = {
  closeModal: () => void;
};

type ModalProps = {
  children: (arg: ChildrenArguments) => ReactNode;
  modalTrigger?: (arg: ModalTriggerArguments) => ReactNode;
  openOnInit?: boolean;
  size?: 'sm' | 'lg';
  onClose?: () => void;
};

const Modal = ({
  children,
  openOnInit = false,
  modalTrigger,
  size = 'sm',
  onClose,
}: ModalProps): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(openOnInit);
  const modal = useRef(null);
  const modalOverlay = useRef(null);

  const fadeIn = useCallback(() => {
    const fadeInTl = anime
      .timeline({
        easing: 'easeOutCubic',
      })
      .add({
        duration: 150,
        opacity: 1,
        targets: modalOverlay.current,
      })
      .add({
        duration: 300,
        targets: modal.current,
        translateY: ['100vh', 0],
      });

    fadeInTl.play();
  }, []);

  const fadeOut = (): void => {
    const fadeOutTl = anime
      .timeline({
        complete: () => setIsModalOpen(false),
        easing: 'easeInCubic',
      })
      .add({
        duration: 300,
        targets: modal.current,
        translateY: [0, '100vh'],
      })
      .add({
        duration: 150,
        opacity: 0,
        targets: modalOverlay.current,
      });

    fadeOutTl.play();
  };

  useEffect(() => {
    if (isModalOpen) {
      fadeIn();
    }
  }, [isModalOpen, fadeIn]);

  const openModal = (): void => {
    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    fadeOut();
    onClose?.();
  };

  return (
    <>
      {modalTrigger?.({ openModal })}

      {isModalOpen &&
        createPortal(
          <div className={clsx('modal', '-react')}>
            {/* To support a11y, we have cancel button inside modal  */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
            <div
              ref={modalOverlay}
              className={clsx('modal__overlay', isModalOpen && '-open')}
              onClick={closeModal}
            />
            <div ref={modal} className={clsx('modal__content', `-${size}`)}>
              <div className="preview">{children({ closeModal })}</div>
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};

export default Modal;
