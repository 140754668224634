import styled from 'styled-components';

const IconBase = styled.svg`
  font-size: var(--fs-24);
  height: 1em;
  width: 1em;
  line-height: 1;

  path,
  polygon {
    fill: currentColor;
  }
`;

export default IconBase;
