import { ReactElement, ReactNode, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

type LazyImageProps = {
  alt?: string;
  fallback?: ReactNode;
  src?: string;
};

const LazyImage = ({ src, alt, fallback }: LazyImageProps): ReactElement<LazyImageProps> => {
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!src) {
      return () => {
        // No cleanup needed
      };
    }
    const image = new Image();
    const onLoad = () => {
      if (image.complete && image.naturalWidth > 0) {
        setIsLoaded(true);
      }
    };
    const onError = () => {
      setIsError(true);
    };
    image.addEventListener('load', onLoad);
    image.addEventListener('error', onError);
    image.src = src;
    onLoad();

    return () => {
      image.removeEventListener('load', onLoad);
      image.removeEventListener('error', onError);
    };
  }, [src]);

  if (isError || !src) {
    return fallback ? <>{fallback}</> : <Skeleton circle height="100%" width="100%" />;
  }

  if (!isLoaded) {
    return <Skeleton circle height="100%" width="100%" />;
  }

  return <img alt={alt} src={src} />;
};

export default LazyImage;
