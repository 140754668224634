import { ReactElement, ReactNode } from 'react';

export const CheckboxCollectionTitle = ({ children }: { children: ReactNode }): ReactElement => (
  <h3 className="checkbox-collection__title -b">{children}</h3>
);
export const CheckboxCollectionBody = ({ children }: { children: ReactNode }): ReactElement => (
  <div className="checkbox-collection__body">{children}</div>
);

const CheckboxCollection = ({ children }: { children: ReactNode }): ReactElement => (
  <div className="checkbox-collection">{children}</div>
);

export default CheckboxCollection;
