import { ItemType } from './molocules/MultiSelectWidget';

const FIRST_ITEMS = ['ALPHA', 'BETA', 'GROWTH'];

const sortPureAlphabetically = (items: ItemType[]): ItemType[] =>
  items.sort((a, b) => {
    const aLabel = a.label.toLowerCase();
    const bLabel = b.label.toLowerCase();

    if (aLabel < bLabel) {
      return -1;
    }
    if (aLabel > bLabel) {
      return 1;
    }
    return 0;
  });

/**
 * Sort Items with ALPHA, BETA and GROWTH first, with remainder done alphabetically.
 *
 */
export const sortAlphabeticallyWithFirstItems = (items: ItemType[]): ItemType[] => {
  const presentFirstItems: ItemType[] = [];
  FIRST_ITEMS.forEach((i) => {
    const s = items.find((is) => is.label === i);
    const sdx = items.findIndex((is) => is.label === i);

    if (s && sdx !== -1) {
      presentFirstItems.push(s);
    }
  });

  const itemsToSort = items.filter((i) => !presentFirstItems.includes(i));

  return presentFirstItems.concat(...sortPureAlphabetically(itemsToSort));
};
