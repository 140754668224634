import { useEffect } from 'react';

const brand = document.title;

export const setPageTitle = (title: string): void => {
  document.title = `${title} | ${brand}`;
};

export const useTitle = (title?: string): void => {
  useEffect(() => {
    if (typeof title === 'string') {
      setPageTitle(title);
    }
  }, [title]);
};
