import { ReactElement } from 'react';

import FlashNotification, {
  FlashNotificationProps,
} from '~/components/notifications/FlashNotification';

const StandaloneFlashNotification = ({
  message,
  header,
  url,
  type,
  onDismiss,
  fadeOutTimeout,
  calendarEvent,
}: FlashNotificationProps): ReactElement<FlashNotificationProps> => {
  return (
    <div className="flash-notifications">
      <FlashNotification
        calendarEvent={calendarEvent}
        fadeOutTimeout={fadeOutTimeout}
        header={header}
        message={message}
        type={type}
        url={url}
        onDismiss={onDismiss}
      />
    </div>
  );
};

export default StandaloneFlashNotification;
