import { ReactElement, SVGProps } from 'react';

type ScreenIconProps = SVGProps<SVGSVGElement>;

const ScreenIcon = (props: ScreenIconProps): ReactElement<ScreenIconProps> => {
  return (
    <svg version="1.1" viewBox="0 0 32 32" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#00AC93" transform="translate(-1010.000000, -150.000000)">
          <g transform="translate(992.000000, 126.000000)">
            <g transform="translate(18.000000, 24.000000)">
              <path d="M16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 Z M22,10 L10,10 L9.8720673,10.0061334 C9.19815456,10.0710496 8.66666667,10.6431373 8.66666667,11.3333333 L8.66666667,11.3333333 L8.66666667,19.3333333 L8.67280005,19.461266 C8.73771626,20.1351788 9.30980392,20.6666667 10,20.6666667 L10,20.6666667 L13.6096667,20.666 L12,22.28 L12.94,23.22 L15.4916667,20.666 L16.0756667,20.666 L18.6294987,23.22 L19.5694987,22.28 L17.9576667,20.666 L22,20.6666667 L22.1278662,20.6605333 C22.8010842,20.5956171 23.3266667,20.0235294 23.3266667,19.3333333 L23.3266667,19.3333333 L23.3333333,11.3333333 L23.3271999,11.2054006 C23.2622837,10.5314879 22.6901961,10 22,10 L22,10 Z M22,11.3333333 L22,19.3333333 L10,19.3333333 L10,11.3333333 L22,11.3333333 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ScreenIcon;
