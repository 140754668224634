import { css } from 'styled-components';

const titleThreeMixin = css`
  font-weight: 600;
  font-size: var(--fs-title-3);
  line-height: var(--lh-title-3);
  letter-spacing: normal;
`;

export default titleThreeMixin;
