import {
  ApolloClient,
  createHttpLink,
  FieldReadFunction,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { relayStylePagination } from '@apollo/client/utilities';
import { sha256 } from 'js-sha256';

import { ConnectionLinkConnection, ConnectionLinkEdge } from '~/operations/catalyst';
import fragments from '~/operations/catalyst/fragments.json';

import authToken, { authorizationHeader } from './authToken';
import config from './config';

type Client = ApolloClient<NormalizedCacheObject>;

const clients: Record<string, Client> = {};

export type InitOptions = {
  token?: string;
  uri?: string;
  withPersistedQueries?: boolean;
};

const relayReadFunction: FieldReadFunction = (
  existing: ConnectionLinkConnection,
  { canRead, readField, args },
) => {
  if (!existing) return undefined;

  let edges: ConnectionLinkEdge[] = [];
  let firstEdgeCursor = '';
  let lastEdgeCursor = '';
  let moreThenFirstElements = false;
  let moreThenLastElements = false;
  let elementsAfterBefore = false;
  let elementsBeforeAfter = false;
  existing.edges.forEach((edge) => {
    // Edges themselves could be Reference objects, so it's important
    // to use readField to access the edge.edge.node property.
    if (canRead(readField('node', edge))) {
      edges.push(edge);
    }
  });

  if (args && args.after) {
    const afterEdgeIdx = edges.findIndex((edge) => edge.cursor === args.after);
    const afterEdge = edges[afterEdgeIdx];

    elementsBeforeAfter = afterEdgeIdx > 0;

    if (afterEdge !== null && afterEdge !== undefined) {
      edges = edges.slice(afterEdgeIdx + 1);
    }
  }

  if (args && args.before) {
    const beforeEdgeIdx = edges.findIndex((edge) => edge.cursor === args.before);
    const beforeEdge = edges[beforeEdgeIdx];

    elementsAfterBefore = beforeEdgeIdx < edges.length - 1;

    if (beforeEdge !== null && beforeEdge !== undefined) {
      edges = edges.slice(0, beforeEdgeIdx);
    }
  }

  if (args && args.first) {
    moreThenFirstElements = edges.length > args.first;
  }

  if (args && args.last) {
    moreThenLastElements = edges.length > args.last;
  }

  if (args && args.first) {
    if (edges.length > args.first) {
      edges = edges.slice(0, args.first);
    }
  }
  if (args && args.last) {
    if (edges.length > args.last) {
      const oldLength = edges.length;
      const newStart = oldLength - args.last;
      edges = edges.slice(newStart, oldLength);
    }
  }

  edges.forEach((edge) => {
    // Edges themselves could be Reference objects, so it's important
    // to use readField to access the edge.edge.node property.
    if (edge.cursor) {
      firstEdgeCursor = firstEdgeCursor || edge.cursor || '';
      lastEdgeCursor = edge.cursor || lastEdgeCursor;
    }
  });

  return {
    // Some implementations return additional Connection fields, such
    // as existing.totalCount. These fields are saved by the merge
    // function, so the read function should also preserve them.
    ...existing,
    edges,
    pageInfo: {
      ...existing.pageInfo,
      
      
      endCursor: lastEdgeCursor,
      

hasNextPage:
        existing.pageInfo.hasNextPage ||
        (args && args.first && moreThenFirstElements) ||
        (args && args.before && elementsAfterBefore) ||
        false,
      

hasPreviousPage:
        existing.pageInfo.hasPreviousPage ||
        (args && args.last && moreThenLastElements) ||
        (args && args.after && elementsBeforeAfter) ||
        false,
      // If existing.pageInfo.{start,end}Cursor are undefined or "", default
// to firstEdgeCursor and/or lastEdgeCursor.
startCursor: firstEdgeCursor,
    },
  };
};

declare global {
  interface Window {
    apiUrl?: string;
  }
}

export const init = ({
  uri,
  token = authToken(),
  withPersistedQueries = true,
}: InitOptions = {}): Client => {
  const url = uri || config.catalystUrl;

  if (clients[url]) {
    return clients[url];
  }

  let httpLink = createHttpLink({
    uri: url,
  });

  // MSW does not like persisted query link, so disabling it in tests
  if (
    withPersistedQueries &&
    process.env.NODE_ENV !== 'test' &&
    process.env.STORYBOOK_MODE !== 'storybook'
  ) {
    httpLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: false }).concat(httpLink);
  }

  const authorization = authorizationHeader(token);
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization,
      },
    };
  });
  const link = authLink.concat(httpLink);

  const client = new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: fragments.possibleTypes,
      typePolicies: {
        RoomAccess: {
          fields: {
            features: {
              merge: (_existing, incoming) => incoming,
            },
          },
        },
        User: {
          fields: {
            connectionLinks: {
              keyArgs: false,
              merge: relayStylePagination().merge,
              read: relayReadFunction,
            },
            connectionRequests: {
              keyArgs: false,
              merge: relayStylePagination().merge,
              read: relayReadFunction,
            },
          },
        },
      },
    }),
    link,
    
    name: 'CFHWebapp',
    // todo: adding current commit hash or some kind of version here would be valuable
version: 'unknown',
  });

  clients[url] = client;

  return client;
};
