import { ButtonHTMLAttributes, forwardRef, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import ButtonBase, { ButtonBaseProps } from './ButtonBase';

type NativeButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonProps = NativeButtonProps &
  ButtonBaseProps & {
    icon?: ReactNode;
  };

const Button = styled(
  forwardRef<HTMLButtonElement, ButtonProps>(
    (
      { variant = 'primary', size = 'medium', children, icon, ...props }: ButtonProps,
      ref,
    ): ReactElement<ButtonProps> => {
      return (
        <ButtonBase ref={ref} size={size} variant={variant} {...props}>
          {icon}
          {children}
        </ButtonBase>
      );
    },
  ),
)``;

export default Button;
