import clsx from 'clsx';
import { ReactElement } from 'react';

import LazyImage from '~/components/shared/atoms/LazyImage';
import DefaultAvatar from '~/components/icons/DefaultAvatar';
import urlFor from '~/lib/urlFor';

export type PortraitProps = {
  avatarUrl?: string | undefined | null;
  className?: string;
  id?: string | null;
  isLink?: boolean;
  name?: string;
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'profile'
    | 'dropdown-item'
    | 'list-item'
    | 'xl'
    | 'expand'
    | 'full';
};

const Portrait = ({
  id,
  name,
  avatarUrl,
  size = 'sm',
  className,
  isLink = true,
}: PortraitProps): ReactElement<PortraitProps> => {
  const avatar = <LazyImage alt={name} fallback={<DefaultAvatar />} src={avatarUrl ?? undefined} />;

  const classes = clsx('portrait', `-${size}`, !avatarUrl && '-default', className);

  return isLink && id ? (
    <a className={classes} href={urlFor(`/attendees/${id}`)} rel="noreferrer" target="_blank">
      {avatar}
    </a>
  ) : (
    <div className={classes}>{avatar}</div>
  );
};

export default Portrait;
