import { createContext, useCallback, useContext } from 'react';

declare global {
  interface Window {
    _paq?: unknown[];
    gtag?: (type: string, action: string, payload: Record<string, string>) => void;
  }
}

type GtagFunc = (command: string, value: string, payload: Record<string, string>) => void;

// No-op when unavailable.
const gtag: () => GtagFunc = () =>
  window.gtag ||
  (() => {
    // Default no-op
  });
const paq = () => window._paq || [];

let currentUrl = document.location.href;

export const page = (): void => {
  // Simple hack to make page titles more accurate, as they may be updated
  // after async API responses.
  setTimeout(() => {
    gtag()('event', 'page_view', {
      page_location: document.location.href,
      page_path: document.location.pathname,
      page_title: document.title,
    });

    paq().push(['setReferrerUrl', currentUrl]);
    paq().push(['setDocumentTitle', document.title]);
    paq().push(['setCustomUrl', document.location.href]);
    paq().push(['trackPageView']);
    paq().push(['enableLinkTracking']);

    // Store the referrer for the next page view.
    currentUrl = document.location.href;
  }, 1000);
};

export type Payload = {
  action: string;
  category: string;
  name?: string;
};

export const track = ({ category, action, name }: Payload): void => {
  gtag()('event', action, {
    event_category: category,
    event_label: name || '',
  });

  paq().push(['trackEvent', category, action, name]);
};

export const search = (query: string): void => {
  paq().push(['trackSiteSearch', query, false, false]);
};

export type TrackingContextType = {
  category: string;
  name?: string;
};

export const TrackingContext = createContext<TrackingContextType>({
  category: 'Unknown',
});

/**
 * Returns a tracking function using the underlying `TrackingContext`.
 */
export const useTracking = (): ((action: string, name?: string) => void) => {
  const { category, name: contextName } = useContext(TrackingContext);

  return useCallback(
    (action: string, name?: string) =>
      track({
        action,
        category,
        name: name || contextName,
      }),
    [category, contextName],
  );
};
