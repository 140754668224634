import { ReactElement, SVGProps } from 'react';

type ChevronIconProps = SVGProps<SVGSVGElement>;

const ChevronIcon = (props: ChevronIconProps): ReactElement<ChevronIconProps> => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M7.41 8L12 12.58 16.59 8 18 9.41l-6 6-6-6z" />
  </svg>
);

export default ChevronIcon;
