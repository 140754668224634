import { ReactElement } from 'react';

import urlFor from '~/lib/urlFor';

import ButtonBase from '../button/ButtonBase';
import ErrorScreen from '../shared/molecules/ErrorScreen';

const PageNotFound = (): ReactElement => {
  return (
    <ErrorScreen title="We couldn't find that page.">
      <ButtonBase as="a" href={urlFor('/')} variant="secondary">
        Go home
      </ButtonBase>
    </ErrorScreen>
  );
};

export default PageNotFound;
