import clsx from 'clsx';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import type { SearchBoxProvided, SearchState } from 'react-instantsearch-core';
import { connectSearchBox, connectStateResults } from 'react-instantsearch-dom';
import { z } from 'zod';

import FilterIcon from '~/components/icons/FilterIcon';

import Popper from '../../popper/Popper';
import SearchBox from '../atoms/SearchBox';

type ExposedSearchBoxProps = {
  placeholderText?: string;
} & SearchBoxProvided;

type SearchBarProps = {
  filters?: () => ReactNode;
  isSearchWithNavigation?: boolean;
  placeholderText?: string;
};

const filtersThatAreApplyed = z.union([z.string(), z.array(z.string())]);

const hasRefinementList = z.object({
  refinementList: z.record(filtersThatAreApplyed),
});

const hasToggle = z.object({
  toggle: z.record(z.boolean()),
});

const filterCountReducer = (prevCount: number, filter: string | string[]): number => {
  if (z.string().min(1).safeParse(filter).success) {
    return prevCount + 1;
  }

  if (z.string().safeParse(filter).success) {
    return prevCount;
  }
  return prevCount + filter.length;
};

const ConnectedSearchBox = connectSearchBox<ExposedSearchBoxProps>(
  ({ placeholderText, currentRefinement, refine }) => (
    <SearchBox
      className="search-bar__search-box"
      placeholderText={placeholderText}
      value={currentRefinement ?? ''}
      onChange={(event) => refine(event.target.value)}
    />
  ),
);

const NumberOfFiltersContainer = connectStateResults(
  ({
    searchState,
    children,
  }: {
    children: (numberOfFilters: number) => ReactNode;
    searchState: SearchState;
  }) => {
    const [numberOfFilters, setNumberOfFilters] = useState(0);

    useEffect(() => {
      const maybeRefinementFilters = hasRefinementList.safeParse(searchState);
      const maybeToggle = hasToggle.safeParse(searchState);
      const ignoredToggleKeys = ['has_video_archive'];

      const refinmentFiltersCount = (() => {
        if (maybeRefinementFilters.success === false) {
          return 0;
        }

        return Object.values(maybeRefinementFilters.data.refinementList).reduce(
          filterCountReducer,
          0,
        );
      })();

      const toggleCount = (() => {
        if (maybeToggle.success === false) {
          return 0;
        }

        return Object.keys(maybeToggle.data.toggle).filter(
          (key) => !ignoredToggleKeys.includes(key) && maybeToggle.data.toggle[key] === true,
        ).length;
      })();

      setNumberOfFilters(refinmentFiltersCount + toggleCount);
    }, [searchState]);

    return <>{children(numberOfFilters)}</>;
  },
);

const SearchBar = ({
  placeholderText,
  filters,
  isSearchWithNavigation,
}: SearchBarProps): ReactElement<SearchBarProps> => (
  <div className={clsx('search-bar', isSearchWithNavigation && '-no-pad')}>
    <ConnectedSearchBox placeholderText={placeholderText} />
    {filters && (
      <Popper className="search-bar__popper" popoutContent={filters}>
        {(activationClassName: string, activatePopper: () => void) => (
          <NumberOfFiltersContainer>
            {(numberOfFilters: number) => (
              <button
                className={clsx('search-bar__filter-button', activationClassName)}
                type="button"
                onClick={activatePopper}
              >
                <FilterIcon
                  className={clsx('search-bar__filter-icon', {
                    'search-bar__filter-icon--filters-applied': numberOfFilters > 0,
                  })}
                />
                {numberOfFilters > 0 && (
                  <div className="search-bar__filter-count">{numberOfFilters}</div>
                )}
              </button>
            )}
          </NumberOfFiltersContainer>
        )}
      </Popper>
    )}
  </div>
);

export default SearchBar;
