import clsx from 'clsx';
import { ReactElement,SVGProps } from 'react';

import IconBase from './IconBase';

const AlertIcon = ({
  className,
  ref,
  ...props
}: SVGProps<SVGSVGElement>): ReactElement => (
  <IconBase
    className={clsx('alert-icon', className)}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        className="alert-icon__path"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 2c-4.416 0-8 3.584-8 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm1 9h-2V7h2v6zm0 4h-2v-2h2v2z"
        fill="#333"
      />
    </g>
  </IconBase>
);

export default AlertIcon;
