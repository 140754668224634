import clsx from 'clsx';
import { ReactElement, SVGProps } from 'react';
import IconBase from './IconBase';

type AcceptIconProps = SVGProps<SVGSVGElement>;

const AcceptIcon = ({ className, ...props }: AcceptIconProps): ReactElement<AcceptIconProps> => (
  <IconBase
    className={clsx('accept-icon', className)}
    height={24}
    version="1.1"
    viewBox="0 0 24 24"
    width={24}
  >
    <g
      className="accept-icon__icon-group"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      {...props}
    >
      <polygon
        className="accept-icon__icon-shape"
        fill="#333333"
        points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"
      />
    </g>
  </IconBase>
);

export default AcceptIcon;
