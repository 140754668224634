import { authorizationHeader } from '../authToken';
import config from '../config';
import currentSlug from '../currentSlug';

export const resolve = (
  path: string,
  pathParams: Record<string, string> = {},
  queryParams: Record<string, any> = {},
): string => {
  pathParams.slug = currentSlug();
  let res = path;

  Object.keys(pathParams).forEach((key) => {
    res = res.replace(`:${key}`, pathParams[key]);
  });

  const queryStr = new URLSearchParams(queryParams).toString();

  if (queryStr) {
    res += `?${queryStr}`;
  }

  return res;
};

export const get = async <T>(path: string): Promise<T> => {
  const res = await fetch(`${config.avengerUrl}${path}`, {
    headers: {
      authorization: authorizationHeader(),
    },
    mode: 'cors',
  });

  if (res.status >= 400) {
    throw new Error(res.statusText);
  }

  const json = await res.json();
  return json.data as T;
};

const request = async <T>(method: string, path: string, data?: object | FormData): Promise<T> => {
  const headers: HeadersInit = {
    authorization: authorizationHeader(),
  };

  if (data && !(data instanceof FormData)) {
    headers['content-type'] = 'application/json';
  }

  const res = await fetch(`${config.avengerUrl}${path}`, {
    method,
    headers,
    mode: 'cors',
    body: !data || data instanceof FormData ? data : JSON.stringify(data),
  });

  if (res.status >= 400) {
    throw new Error(res.statusText);
  }

  if (res.status === 204) {
    return undefined as unknown as T;
  }

  const json = await res.json();
  return json.data as T;
};

export const post = async <T>(path: string, data?: object | FormData): Promise<T> => {
  return request('post', path, data);
};

export const put = async <T>(path: string, data?: object | FormData): Promise<T> => {
  return request('put', path, data);
};

export const destroy = async <T>(path: string, data?: object | FormData): Promise<T> => {
  return request('delete', path, data);
};
