import { ReactElement, SVGProps } from 'react';

type TickCircleIconProps = SVGProps<SVGSVGElement>;

const TickCircleIcon = (props: TickCircleIconProps): ReactElement<TickCircleIconProps> => (
  <svg height="32px" version="1.1" viewBox="0 0 32 32" width="32px" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g className="icon-fill-brand-primary" transform="translate(-1013.000000, -61.000000)">
        <g transform="translate(995.000000, 17.000000)">
          <g transform="translate(18.000000, 44.000000)">
            <path d="M16,0 C24.836556,0 32,7.163444 32,16 C32,18.7454517 31.3085139,21.3293983 30.0902029,23.5871785 C27.387238,28.5963267 22.0911043,32 16,32 C11.6799162,32 7.75972493,30.2878566 4.88096955,27.5051131 C1.87134769,24.5958677 0,20.5164722 0,16 C0,7.163444 7.163444,0 16,0 Z M23.59,9.59 L13,20.17 L8.83,16 L7.41,17.41 L13,23 L25,11 L23.59,9.59 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default TickCircleIcon;
