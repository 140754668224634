import { css } from 'styled-components';

const subheadMixin = css`
  font-weight: 400;
  text-transform: uppercase;
  font-size: var(--fs-subhead);
  line-height: var(--lh-subhead);
  letter-spacing: 1px;
`;

export default subheadMixin;
