import { ButtonHTMLAttributes, forwardRef, ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';

type NativeButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export type IconButtonProps = NativeButtonProps & {
  active?: boolean;
  children?: ReactNode;
  size?: 'small' | 'medium';
};

const StyledButton = styled.button<{
  active: boolean;
  size: 'small' | 'medium';
}>`
  background-color: transparent;
  transition: background-color 0.5s ease;
  cursor: pointer;
  line-height: 1em;
  border-radius: var(--s-bw);
  position: relative;
  min-width: 2rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:disabled {
    color: var(--c-grey);
  }

  svg {
    height: 1em;
    width: 1em;
  }

  svg,
  path,
  polygon {
    fill: currentColor;
  }

  ${(props) => {
    switch (props.size) {
      case 'small':
        return css`
          padding: 0.125rem;
          font-size: 1.25rem;
        `;
      case 'medium':
        return css`
          padding: 0.25rem;
          font-size: 1.5rem;
        `;
      default:
        return null;
    }
  }}

  ${(props) =>
    props.active &&
    css`
      background-color: rgba(0, 0, 0, 0.05);
      color: var(--c-brand-primary);
    `}
`;

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { size = 'medium', active = false, children, ...rest }: IconButtonProps,
    ref,
  ): ReactElement<IconButtonProps> => {
    return (
      <StyledButton ref={ref} active={active} size={size} {...rest}>
        {children}
      </StyledButton>
    );
  },
);

export default IconButton;
