import styled from 'styled-components';

// Centers children inside parent.
const Center = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export default Center;
