import clsx from 'clsx';
import { ReactElement, ReactNode, useState } from 'react';

type PopperProps = {
  children: (
    activationClassName: string,
    activatePopper: () => void,
    isPopperPanelOpen?: boolean,
  ) => ReactNode;
  className?: string;
  greyOverlay?: boolean;
  headers?: () => ReactNode;
  popoutContent?: (isPanelOpen?: boolean) => ReactNode;
};

const Popper = ({
  className,
  children,
  greyOverlay = false,
  headers,
  popoutContent,
}: PopperProps): ReactElement<PopperProps> => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const activatePopper = () => setIsPopperOpen(true);
  const deactivePopper = () => setIsPopperOpen(false);
  const activationClassName = clsx('popper__activation', {
    'popper__activation--on': isPopperOpen,
  });

  return (
    <div className={clsx('popper', className)}>
      {children(activationClassName, activatePopper, isPopperOpen)}
      <div
        className={clsx('popper__overlay', { 'popper__overlay--grey': greyOverlay })}
        role="presentation"
        onClick={deactivePopper}
      />
      <div className="popper__expanded">
        {headers && <header className="popper__header">{headers()}</header>}
        {popoutContent && (
          <div className="indicator__content popper__content">{popoutContent(isPopperOpen)}</div>
        )}
      </div>
    </div>
  );
};

export default Popper;
