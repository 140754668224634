import { ReactElement } from 'react';
import { FieldError } from 'react-hook-form';

import Dropdown, { OptionType } from './Dropdown';
import FormField from './FormField';

export type DropdownInputProps = {
  className?: string;
  error?: FieldError;
  isRequired?: boolean;
  label: string;
  name: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  options: OptionType[];
  placeholder?: string;
  value: string;
};

const DropdownInput = ({
  error,
  name,
  placeholder,
  label,
  isRequired,
  onChange,
  options,
  value,
  onBlur,
  className,
}: DropdownInputProps): ReactElement<DropdownInputProps> => {
  return (
    <FormField
      className={className}
      error={error}
      isRequired={isRequired}
      label={label}
      name={name}
    >
      <Dropdown
        name={name}
        options={options}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
    </FormField>
  );
};

export default DropdownInput;
