import clsx from 'clsx';
import { useSelect , UseSelectStateChange } from 'downshift';
import { ReactElement } from 'react';

import ChevronIcon from '~/components/icons/ChevronIcon';

type SelectProps<T> = {
  disabled?: boolean;
  isRequired?: boolean;
  itemToString?: (item: T | null) => string;
  items: T[];
  label?: string;
  onChange: (changes: UseSelectStateChange<T>) => void;
  value?: T;
  placeholder?: string;
};

const Select = <T,>({
  items,
  value,
  label,
  onChange,
  isRequired,
  itemToString = (item: T | null): string => (item ? String(item) : ''),
  disabled = false,
  placeholder,
}: SelectProps<T>): ReactElement<SelectProps<T>> =>  {
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect<T>({
    itemToString,
    items,
    onSelectedItemChange: onChange,
    initialSelectedItem: value,
  });

  return (

    <div className="select">
      {label && (
        <h2 className="-b">
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label {...getLabelProps()}>
            {label}
            {isRequired && '*'}
          </label>
        </h2>
      )}
      <button
        className={clsx('select__button', isOpen && '-open', disabled && '-disabled')}
        type="button"
        {...getToggleButtonProps({ disabled })}
      >
        {value ? itemToString(value) : placeholder}
        <div className={clsx('select__icon', isOpen && '-open', disabled && '-disabled')}>
          <ChevronIcon />
        </div>
      </button>
      <ul {...getMenuProps()} className={clsx('select__list', isOpen && '-open')}>
        {isOpen &&
          items.map((item, index) => (
            <li
              key={itemToString(item)}
              className={clsx('select__list-item', highlightedIndex === index && '-highlighted')}
              {...getItemProps({ disabled, index, item })}
            >
              {itemToString(item)}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default Select;
