import { useMutation, useQuery } from '@apollo/client';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import Skeleton from 'react-loading-skeleton';

import { useTracking } from '~/lib/analytics';
import {
  AppearanceConnectionStateDocument,
  AppearanceConnectDocument,
  ConnectionState,
} from '~/operations/catalyst';

import Button, { ButtonProps } from '../button/Button';
import AcceptIcon from '../icons/AcceptIcon';
import CompanyRegisterIcon from '../icons/CompanyRegisterIcon';
import Modal from '../others/Modal';

export type ExhibitorConnectionButtonProps = {
  attributionSource?: string;
  className?: string;
  id: string;
  name?: string;
  size?: ButtonProps['size'];
};

const ExhibitorConnectionButton = ({
  id,
  name,
  attributionSource,
  className,
  size,
}: ExhibitorConnectionButtonProps): ReactElement => {
  const { t } = useTranslation();
  const track = useTracking();

  // As an optimization for list views, only run the query once this component is in view.
  const { ref, inView } = useInView({ triggerOnce: true });

  const { data } = useQuery(AppearanceConnectionStateDocument, {
    variables: {
      id,
    },
    skip: !inView,
  });

  const [connect] = useMutation(AppearanceConnectDocument, {
    variables: {
      id,
      source: attributionSource,
    },
  });

  if (data?.appearance?.connectionState === ConnectionState.NotConnected) {
    return (
      <Modal
        modalTrigger={({ openModal }): ReactElement => (
          <Button
            className={className}
            icon={<CompanyRegisterIcon />}
            size={size}
            onClick={(): void => {
              track('Clicked connect');
              openModal();
            }}
          >
            {t('connections.connect')}
          </Button>
        )}
      >
        {({ closeModal }): ReactElement => (
          <>
            <div className="preview__generic">
              <h2 className="preview__generic__heading">
                {t('connections.connectWith', { name })}
              </h2>
              <div className="preview__generic__text">
                <Trans i18nKey="connections.exhibitorDisclaimer" t={t}>
                  <p>
                    By clicking CONNECT, you agree that your name, company, title, email and country
                    will be shared with the Company, and that they may share their latest news and
                    offers with you by email.
                  </p>
                  <p>
                    You may unsubscribe at any time via the unsubscribe button provided in emails.
                    Providing the Partner with your information may involve transferring it to
                    another country. Personal Data will be processed in accordance with the
                    Partner&apos;s Privacy Policy.
                  </p>
                </Trans>
              </div>
            </div>
            <nav className="preview__nav">
              <Button
                variant="secondary"
                onClick={(): void => {
                  track('Cancelled connection');
                  closeModal();
                }}
              >
                {t('labels.cancel')}
              </Button>
              <Button
                onClick={(): void => {
                  connect();
                  track('Confirmed connection');
                  closeModal();
                }}
              >
                {t('connections.connect')}
              </Button>
            </nav>
          </>
        )}
      </Modal>
    );
  }

  if (data?.appearance?.connectionState === ConnectionState.Connected) {
    return (
      <Button
        className={className}
        icon={<AcceptIcon />}
        size={size}
        variant="brand"
        onClick={() => connect()}
      >
        {t('connections.connected')}
      </Button>
    );
  }

  return (
    <Button ref={ref} className={className} icon={<CompanyRegisterIcon />} size={size}>
      {t('connections.connect')}
    </Button>
  );
};

export default ExhibitorConnectionButton;
