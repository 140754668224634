import { ReactElement } from 'react';

import ArrowLeftIcon from '~/components/icons/ArrowLeftIcon';
import ArrowRightIcon from '~/components/icons/ArrowRightIcon';

type PaginationButtonProps = {
  isDisabled: boolean;
  onClick: () => void;
  type: PaginationButtonType;
};

export enum PaginationButtonType {
  Backward = 'BACKWARD',
  Forward = 'FORWARD'
}

const icons = {
  [PaginationButtonType.Forward]: <ArrowRightIcon />,
  [PaginationButtonType.Backward]: <ArrowLeftIcon />,
};

const PaginationButton = ({
  onClick,
  isDisabled,
  type,
}: PaginationButtonProps): ReactElement<PaginationButtonProps> => {
  return (
    <button className="pagination__button" disabled={isDisabled} type="button" onClick={onClick}>
      {icons[type]}
    </button>
  );
};

export default PaginationButton;
