import clsx from 'clsx';
import { ReactElement, SVGProps } from 'react';

type FilterIconProps = SVGProps<SVGSVGElement> & {
  className: string;
};

const FilterIcon = ({ className, ...props }: FilterIconProps): ReactElement<FilterIconProps> => (
  <svg className={clsx('filter-icon', className)} height={24} width={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        className="filter-icon__burger-lines"
        d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"
        fill="#333"
      />
    </g>
  </svg>
);

export default FilterIcon;
