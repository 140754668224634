import { ReactElement } from 'react';

import CheckboxIcon from '~/components/icons/CheckboxIcon';
import CheckedCheckboxIcon from '~/components/icons/CheckedCheckboxIcon';

type CheckboxProps = {
  checked: boolean;
  id?: string;
  name?: string;
  setChecked: (checked: boolean) => void;
};

const Checkbox = ({ checked, id, name, setChecked }: CheckboxProps): ReactElement => {
  return (
    <span className="checkbox">
      <input
        checked={checked}
        className="checkbox__input"
        id={id}
        name={name}
        type="checkbox"
        onChange={(e) => setChecked(e.target?.checked ?? false)}
      />
      {checked ? (
        <CheckedCheckboxIcon className="checkbox__icon" />
      ) : (
        <CheckboxIcon className="checkbox__icon" />
      )}
    </span>
  );
};

export default Checkbox;
