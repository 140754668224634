import { ReactElement, SVGProps } from 'react';

type DefaultAvatarProps = SVGProps<SVGSVGElement> & {
  className?: string;
};

const DefaultAvatar = ({
  className,
  ...rest
}: DefaultAvatarProps): ReactElement<DefaultAvatarProps> => (
  <svg className={className} height="56px" viewBox="0 0 56 56" width="56px" {...rest}>
    <g fill="none" fillRule="evenodd" id="New" stroke="none" strokeWidth="1">
      <g id="defaultAvatar">
        <circle className="icon-fill-brand-primary " cx="28" cy="28" id="Oval" r="28" />
        <g id="Icon-/-User-/-Filled" transform="translate(16.000000, 16.000000)">
          <polygon id="Path" points="0 0 24 0 24 24 0 24" />
          <path
            d="M12,12 C14.21,12 16,10.21 16,8 C16,5.79 14.21,4 12,4 C9.79,4 8,5.79 8,8 C8,10.21 9.79,12 12,12 Z M12,14 C9.33,14 4,15.34 4,18 L4,20 L20,20 L20,18 C20,15.34 14.67,14 12,14 Z"
            fill="#FFFFFF"
            fillRule="evenodd"
            id="Colour"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DefaultAvatar;
