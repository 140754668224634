import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import currentSlug from '~/lib/currentSlug';

import en from './locales/en.json';
import en_ces22 from './locales/en-ces22.json';
import en_iid21 from './locales/en-iid21.json';
import en_ws21 from './locales/en-ws21.json';
import en_ws23 from './locales/en-ws23.json';

const slug = currentSlug();

void i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  lng: slug ? `en-${slug}` : 'en',
  lowerCaseLng: true,
  resources: {
    en,
    'en-ces22': en_ces22,
    'en-iid21': en_iid21,
    'en-ws21': en_ws21,
    'en-ws23': en_ws23,
  },
});

export default i18n;
