import { ReactElement, SVGProps } from 'react';
import IconBase from './IconBase';

type CompanyRegisterIconProps = SVGProps<SVGSVGElement>;

const CompanyRegisterIcon = (
  props: CompanyRegisterIconProps,
): ReactElement<CompanyRegisterIconProps> => (
  <IconBase height="24px" version="1.1" viewBox="0 0 24 24" width="24px">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" {...props}>
      <path
        d="M18,3 L18,7 L24,7 L24,21 L8,21 L8,15 L12,15 L12,13 L11,13 L11,11 L12,11 L12,9 L8,9 L8,3 L18,3 Z M12,17 L10,17 L10,19 L12,19 L12,17 Z M16,17 L14,17 L14,19 L16,19 L16,17 Z M22,9 L18,9 L18,11 L20,11 L20,13 L18,13 L18,15 L20,15 L20,17 L18,17 L18,19 L22,19 L22,9 Z M6,8 L6,11 L9,11 L9,13 L6,13 L6,16 L4,16 L4,13 L1,13 L1,11 L4,11 L4,8 L6,8 Z M16,13 L14,13 L14,15 L16,15 L16,13 Z M16,9 L14,9 L14,11 L16,11 L16,9 Z M12,5 L10,5 L10,7 L12,7 L12,5 Z M16,5 L14,5 L14,7 L16,7 L16,5 Z"
        fill="currentColor"
      />
    </g>
  </IconBase>
);

export default CompanyRegisterIcon;
