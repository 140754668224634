import { ReactElement, ReactNode } from 'react';

import NoResults from '~/components/icons/NoResults';

export type ErrorScreenProps = {
  children?: ReactNode;
  title?: string;
};

const ErrorScreen = ({ title, children }: ErrorScreenProps): ReactElement<ErrorScreenProps> => {
  return (
    <div className="error-screen">
      <NoResults />
      <h2 className="error-screen__header">{title || 'Something went wrong.'}</h2>
      {children}
    </div>
  );
};

export default ErrorScreen;
