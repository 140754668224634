import useSWRImmutable from 'swr/immutable';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { ApiPaths, components, operations } from './schema';
import { destroy, get, post, resolve, put } from './util';

export const useAlgoliaSettings = (
  config?: SWRConfiguration,
): SWRResponse<components['schemas']['AlgoliaSettings']> => {
  const path = resolve(ApiPaths.getAlgoliaSettings);

  return useSWRImmutable<components['schemas']['AlgoliaSettings']>(path, get, config);
};

export const useConference = (
  config?: SWRConfiguration,
): SWRResponse<components['schemas']['Conference']> => {
  const path = resolve(ApiPaths.getConference);

  return useSWR<components['schemas']['Conference']>(path, get, config);
};

export const useMe = (config?: SWRConfiguration): SWRResponse<components['schemas']['Me']> => {
  const path = resolve(ApiPaths.getUser);

  return useSWR<components['schemas']['Me']>(path, get, config);
};

export const useInvestorMeetingInfo = (
  config?: SWRConfiguration,
): SWRResponse<components['schemas']['InvestorMeetingInfo']> => {
  const path = resolve(ApiPaths.getInvestorMeetingInfo);

  return useSWR<components['schemas']['InvestorMeetingInfo']>(path, get, config);
};

export const useStartupMeetingInfo = (
  config?: SWRConfiguration,
): SWRResponse<components['schemas']['StartupMeetingInfo']> => {
  const path = resolve(ApiPaths.getStartupMeetingInfo);

  return useSWR<components['schemas']['StartupMeetingInfo']>(path, get, config);
};

export const useListLeads = (
  appearanceId: string,
  page: number,
  config?: SWRConfiguration,
): SWRResponse<components['schemas']['Lead'][]> => {
  const path = resolve(ApiPaths.listLeads, { appearanceId }, { page });

  return useSWR<components['schemas']['Lead'][]>(path, get, config);
};

export const useVisaLetterRequest = (
  config?: SWRConfiguration,
): SWRResponse<components['schemas']['VisaLetterRequest']> => {
  const path = resolve(ApiPaths.getVisaLetterRequest);

  return useSWR<components['schemas']['VisaLetterRequest']>(path, get, config);
};

export const useRegistrationLetterRequest = (
  config?: SWRConfiguration,
): SWRResponse<components['schemas']['RegistrationLetterRequest']> => {
  const path = resolve(ApiPaths.getRegistrationCertificateLetterRequest);

  return useSWR<components['schemas']['RegistrationLetterRequest']>(path, get, config);
};

export const useCountries = (
  config?: SWRConfiguration,
): SWRResponse<components['schemas']['Country'][]> => {
  const path = resolve(ApiPaths.listCountries);

  return useSWRImmutable<components['schemas']['Country'][]>(path, get, config);
};

export const useTopics = (
  config?: SWRConfiguration,
): SWRResponse<components['schemas']['Topic'][]> => {
  const path = resolve(ApiPaths.listTopics);

  return useSWRImmutable<components['schemas']['Topic'][]>(path, get, config);
};

export const useIndustries = (
  config?: SWRConfiguration,
): SWRResponse<components['schemas']['Industry'][]> => {
  const path = resolve(ApiPaths.listIndustries);

  return useSWRImmutable<components['schemas']['Industry'][]>(path, get, config);
};

export const requestVisaLetter = async (
  params: operations['requestVisaLetter']['requestBody']['content']['application/json'],
): Promise<components['schemas']['VisaLetterRequest']> => {
  const path = resolve(ApiPaths.requestVisaLetter);

  return post(path, params);
};

export const requestRegistrationCertificateLetter = async (): Promise<
  components['schemas']['RegistrationLetterRequest']
> => {
  const path = resolve(ApiPaths.requestRegistrationCertificateLetter);

  return post(path);
};

export const selectInvestorMeetingSession = async (
  sessionId: string,
): Promise<components['schemas']['InvestorMeetingSession']> => {
  const path = resolve(ApiPaths.selectInvestorMeetingSession);
  const params: operations['selectInvestorMeetingSession']['requestBody']['content']['application/json'] =
    { session_id: sessionId };

  return post(path, params);
};

export const addInvestorMeetingSelection = async (appearanceId: string): Promise<void> => {
  const path = resolve(ApiPaths.addInvestorMeetingSelection);
  const params: operations['addInvestorMeetingSelection']['requestBody']['content']['application/json'] =
    { appearance_id: appearanceId };

  return post(path, params);
};

export const removeInvestorMeetingSelection = async (appearanceId: string): Promise<void> => {
  const path = resolve(ApiPaths.removeInvestorMeetingSelection);
  const params: operations['removeInvestorMeetingSelection']['requestBody']['content']['application/json'] =
    { appearance_id: appearanceId };

  return destroy(path, params);
};

export const submitInvestorMeeting = async (): Promise<void> => {
  const path = resolve(ApiPaths.submitInvestorMeeting);
  const params: operations['submitInvestorMeeting']['requestBody']['content']['application/json'] =
    { accept_gdpr: true };

  return post(path, params);
};

export const acceptStartupMeetingSession = async (
  sessionId: string,
  sessionTimeslotId: string,
): Promise<components['schemas']['StartupMeetingSession']> => {
  const path = resolve(ApiPaths.acceptStartupMeetingSession, { sessionId });
  const params: operations['acceptStartupMeetingSession']['requestBody']['content']['application/json'] =
    { session_timeslot_id: sessionTimeslotId };

  return post(path, params);
};

export const rejectStartupMeetingSession = async (
  sessionId: string,
): Promise<components['schemas']['StartupMeetingSession']> => {
  const path = resolve(ApiPaths.rejectStartupMeetingSession, { sessionId });

  return post(path);
};

export const introduceStartupMeetingSession = async (
  sessionId: string,
): Promise<components['schemas']['StartupMeetingSession']> => {
  const path = resolve(ApiPaths.introduceStartupMeetingSession, { sessionId });

  return post(path);
};

export const acceptStartupMeetingGdpr = async (): Promise<
  components['schemas']['StartupMeetingSession']
> => {
  const path = resolve(ApiPaths.acceptStartupMeetingGdpr);
  const params: operations['acceptStartupMeetingGdpr']['requestBody']['content']['application/json'] =
    { accept_gdpr: true };

  return post(path, params);
};

export const exportLeads = async (
  appearanceId: string,
): Promise<components['schemas']['StartupMeetingSession']> => {
  const path = resolve(ApiPaths.exportLeads, { appearanceId });

  return post(path);
};

export const uploadAvatar = async (
  avatar: File,
): Promise<components['schemas']['UploadedAvatar']> => {
  const path = resolve(ApiPaths.uploadAvatar);
  const data = new FormData();
  data.append('avatar', avatar);

  return post(path, data);
};

export const createOtpChallenge = async (
  login: string,
): Promise<components['schemas']['OtpChallenge']> => {
  const path = resolve(ApiPaths.createOtpChallenge);
  const params: operations['createOtpChallenge']['requestBody']['content']['application/json'] = {
    login,
    app: 'webapp',
  };

  return post(path, params);
};

export const verifyOtpChallenge = async (
  id: string,
  code: string,
): Promise<components['schemas']['OtpChallengeResponse']> => {
  const path = resolve(ApiPaths.verifyOtpChallenge, { id });
  const params: operations['verifyOtpChallenge']['requestBody']['content']['application/json'] = {
    code,
  };

  return post(path, params);
};

export const updateProfile = async (
  params: operations['updateProfile']['requestBody']['content']['application/json'],
): Promise<components['schemas']['Me']> => {
  const path = resolve(ApiPaths.updateProfile);

  return put(path, params);
};
