import { DateTime } from 'luxon';
import { ReactElement, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { useTracking } from '~/lib/analytics';
import urlFor from '~/lib/urlFor';
import { NotificationMessage } from '~/types/notifications';

import usePubnubMessages from '../realtime/usePubnubMessages';
import usePubnubMessagesCounter from '../realtime/usePubnubMessagesCounter';

type NotificationStreamProps = {
  channel: string;
  isPanelOpen: boolean;
};

export const NOTIFICATIONS_PER_PAGE = 10;

const dateToLocalTime = (dateString: string) =>
  DateTime.fromISO(dateString).setLocale('en-us').toFormat('h:mma');

export const makeMessageText = (calendar_event: { starts_at: string, title: string; }): string =>
  `Reminder: '${calendar_event.title}' is starting soon, at ${dateToLocalTime(
    calendar_event.starts_at,
  )}`;

const NotificationStream = ({ isPanelOpen, channel }: NotificationStreamProps): ReactElement => {
  const track = useTracking();
  const { messages, historyLoaded, fetchMessages, isFetching, addMessageAction } =
    usePubnubMessages<NotificationMessage>({
      channel,
      includeMessageActions: true,
      isReverse: true,
      messagePerPage: NOTIFICATIONS_PER_PAGE,
      sortingOrder: 'desc',
    });

  const { setNewMessagesCounter } = usePubnubMessagesCounter({ channel });

  useEffect(() => {
    if (messages.length && !isFetching && isPanelOpen) {
      const latestNotification = messages[0];
      const messageReadAction = latestNotification?.actions?.receipt?.message_mark_as_read;

      if (!messageReadAction) {
        addMessageAction({
          action: {
            type: 'receipt',
            value: 'message_mark_as_read',
          },
          messageTimetoken: latestNotification.timetoken,
        });
      }
    }
  }, [addMessageAction, messages, channel, historyLoaded, isFetching, isPanelOpen]);

  useEffect(() => {
    const readNotificationIndex = messages.findIndex(
      ({ actions }) => actions?.receipt?.message_mark_as_read,
    );
    if (readNotificationIndex > 0) {
      setNewMessagesCounter(readNotificationIndex);
    }
  }, [messages, setNewMessagesCounter]);

  return (
    <>
      <InfiniteScroll
        initialLoad
        hasMore={!historyLoaded}
        loadMore={() => fetchMessages()}
        loader={
          <div key={0} className="loader">
            Loading ...
          </div>
        }
        threshold={15}
        useWindow={false}
      >
        {messages.map(({ data: { body, url: givenUrl, calendar_event, type }, id, sent_at }) => {
          const formattedTimestamp = sent_at && dateToLocalTime(sent_at);
          const url = urlFor(givenUrl);

          if (type === 'reminder' && calendar_event) {
            return (
              <a
                key={id}
                className="notification-item"
                href={url}
                onClick={() => track('Clicked notification entry', type)}
              >
                <div className="notification-item__body">{makeMessageText(calendar_event)}</div>
                {formattedTimestamp && (
                  <div className="notification-item__timestamp" title={sent_at}>
                    Received at {formattedTimestamp}
                  </div>
                )}
              </a>
            );
          }

          return body ? (
            <a
              key={id}
              className="notification-item"
              href={url}
              onClick={() => track('Clicked notification', type)}
            >
              <div className="notification-item__body">{body}</div>
              {formattedTimestamp && (
                <div className="notification-item__timestamp" title={sent_at}>
                  Received at {formattedTimestamp}
                </div>
              )}
            </a>
          ) : null;
        })}
      </InfiniteScroll>
      {!messages.length && (
        <div className="empty">
          <span>All caught up!</span>
        </div>
      )}
    </>
  );
};

export default NotificationStream;
