/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useTracking } from '~/lib/analytics';
import paramaterize from '~/lib/parametize';
import urlFor from '~/lib/urlFor';
import { formatCompanyInfo } from '~/lib/utils';

import Portrait from './Portrait';

type AttendeeCardProps = {
  avatarUrl?: string;
  cardRole?: string;
  children?: ReactNode;
  companyName?: string;
  firstName?: string;
  id?: string;
  jobTitle?: string;
  lastName?: string;
  name?: string;
  theme?: 'dense';
};

const AttendeeCard = ({
  id,
  cardRole,
  avatarUrl,
  firstName,
  lastName,
  name,
  jobTitle,
  companyName,
  theme,
  children,
}: AttendeeCardProps): ReactElement<AttendeeCardProps> => {
  const interaction = children ? <nav className="card__interaction">{children}</nav> : null;
  const track = useTracking();

  if (!id) {
    return (
      <div className={clsx('attendee-item directory-item')}>
        <Skeleton height="208px" />
      </div>
    );
  }

  return (
    <div className={clsx('attendee-item directory-item card -link', theme && `-${theme}`)}>
      <a
        className="li-link"
        href={urlFor(`/attendees/${id}`)}
        onClick={(): void => track('Clicked attendee card')}
      />
      <div className={clsx('card__banner accreditation', cardRole && `-${paramaterize(cardRole)}`)}>
        <div className="card__stack">
          <div className="attendee-stack -lg">
            <div className="attendee-stack__attendee">
              <Portrait avatarUrl={avatarUrl} size="lg" />
            </div>
          </div>
        </div>
      </div>
      <div className="card__content">
        <div className="card__heading">
          {name || [firstName, lastName].filter(Boolean).join(' ')}
        </div>
        <div className="info">{formatCompanyInfo(jobTitle, companyName)}</div>
      </div>
      {interaction}
    </div>
  );
};

export default AttendeeCard;
