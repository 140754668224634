import currentSlug from './currentSlug';

const urlFor = (path: string): string => {
  const slug = currentSlug();

  const prefixedPattern = new RegExp(`^\\/${slug}\\/`);

  if (prefixedPattern.test(path)) {
    // path already prefixed as we want it
    return path;
  }

  // transform `/foo/bar` to `/cc20/foo/bar`
  return `/${slug}${path}`;
};

export default urlFor;
