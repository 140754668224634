import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

import ErrorMessage from './ErrorMessage';

type FormFieldProps = {
  children?: ReactNode;
  className?: string;
  error?: FieldError;
  isDisabled?: boolean;
  isRequired?: boolean;
  label: ReactNode;
  name: string;
};

const FormField = ({
  label,
  name,
  error,
  isRequired = false,
  isDisabled = false,
  children,
  className,
}: FormFieldProps): ReactElement<FormFieldProps> => {
  return (
    <div className={clsx('form-field', isDisabled && '-disabled', className)}>
      {typeof label === 'string' ? (
        <label className="form__field-label -b" htmlFor={name} id={`${name}-label`}>
          {label}
          {!isRequired && ' (optional)'}
        </label>
      ) : (
        label
      )}
      {children}
      {error?.type && error?.message ? (
        <ErrorMessage name={name}>{error.message}</ErrorMessage>
      ) : null}
    </div>
  );
};

export default FormField;
