/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import anime from 'animejs';
import clsx from 'clsx';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { useTracking } from '~/lib/analytics';
import CloseIcon from '~/components/icons/CloseIcon';
import ScreenIcon from '~/components/icons/ScreenIcon';
import TickCircleIcon from '~/components/icons/TickCircleIcon';
import AlertIcon from '../icons/AlertIcon';

import { makeMessageText } from './NotificationStream';

export type FlashTypes = 'reminder' | 'success' | 'error';

export type CalendarEvent = {
  starts_at: string;
  title: string;
};

export type FlashNotificationProps = {
  calendarEvent?: CalendarEvent;
  fadeOutTimeout?: number;
  header?: string;
  message: string;
  onDismiss?: () => void;
  type?: FlashTypes;
  url?: string;
};

const icons: Record<FlashTypes, ReactElement> = {
  reminder: <ScreenIcon />,
  success: <TickCircleIcon />,
  error: <AlertIcon />,
};

const FlashNotification = ({
  message,
  header,
  url,
  type,
  calendarEvent,
  onDismiss,
  fadeOutTimeout = 3000,
}: FlashNotificationProps): ReactElement => {
  const track = useTracking();
  const notificationRef = useRef(null);
  const [notificationTimout, setNotificationTimeout] = useState<number | null>(null);

  const fadeOut = useCallback(() => {
    anime({
      complete: onDismiss,
      duration: 300,
      easing: 'easeInCubic',
      offset: 0,
      opacity: 0,
      targets: notificationRef.current,
      translateY: -10,
    });
  }, [onDismiss]);

  const startTimeout = useCallback(() => {
    const timeout = window.setTimeout(fadeOut, fadeOutTimeout);
    setNotificationTimeout(timeout);
  }, [fadeOut, fadeOutTimeout, setNotificationTimeout]);

  const fadeIn = useCallback(() => {
    anime({
      complete: startTimeout,
      duration: 300,
      easing: 'easeOutCubic',
      offset: 0,
      opacity: 1,
      targets: notificationRef.current,
      translateY: [10, 0],
    });
  }, [startTimeout]);

  const keepAlive = () => {
    if (notificationTimout) {
      clearTimeout(notificationTimout);
      setNotificationTimeout(null);
    }
  };

  const restartCountdown = () => {
    startTimeout();
  };

  useEffect(() => {
    fadeIn();
  }, [fadeIn]);

  useEffect(() => {
    return () => {
      if (notificationTimout) {
        clearTimeout(notificationTimout);
      }
    };
  }, [notificationTimout]);

  const isReminder = type === 'reminder';

  const messageToRender = isReminder && calendarEvent ? makeMessageText(calendarEvent) : message;

  const notificationContent = (
    <div className="notification__pill" onClick={() => track('Clicked flash notification', type)}>
      <button className="notification__close" type="button" onClick={fadeOut}>
        <CloseIcon />
      </button>
      {type && icons[type] && <div className="notification__icon"> {icons[type]}</div>}
      <div className="notification__body">
        {header && <div className="notification__header">{header}</div>}
        <span className="notification__msg">{messageToRender}</span>
        {url && isReminder && (
          <a className="notification__link" href={url}>
            Join the session
          </a>
        )}
      </div>
    </div>
  );

  if (url && !isReminder) {
    return (
      <a
        ref={notificationRef}
        className={clsx('notification')}
        href={url}
        onMouseEnter={keepAlive}
        onMouseLeave={restartCountdown}
      >
        {notificationContent}
      </a>
    );
  }

  return (
    <div
      ref={notificationRef}
      className={clsx('notification')}
      onMouseEnter={keepAlive}
      onMouseLeave={restartCountdown}
    >
      {notificationContent}
    </div>
  );
};

export default FlashNotification;
