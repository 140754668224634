import { ReactElement, SVGProps } from 'react';

type ArrowRightIconProps = SVGProps<SVGSVGElement>;

const ArrowRightIcon = (props: ArrowRightIconProps): ReactElement<ArrowRightIconProps> => (
  <svg viewBox="0 0 24 24" {...props}>
    <g>
      <polygon points="12.0547945 4 10.635137 5.41965753 16.2533562 11.0479452 4 11.0479452 4 13.0616438 16.2533562 13.0616438 10.635137 18.6899315 12.0547945 20.109589 20.109589 12.0547945" />
    </g>
  </svg>
);

export default ArrowRightIcon;
