export const formatCompanyInfo = (
  jobTitle?: string | null,
  companyName?: string | null,
  joinWith = ' at ',
): string => [jobTitle, companyName].filter(Boolean).join(joinWith);

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export const urlParameters = (data: Record<string, string | number>): string =>
  Object.entries(data)
    .map((e) => e.join('='))
    .join('&');
