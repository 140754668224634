import clsx from 'clsx';
import { ChangeEventHandler, ReactElement } from 'react';

import SearchIcon from '~/components/icons/SearchIcon';

type SearchBoxProps = {
  className?: string;
  isSearchWithNavigation?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholderText?: string;
  value: string;
};

const SearchBox = ({
  value,
  onChange,
  placeholderText,
  className,
  isSearchWithNavigation,
}: SearchBoxProps): ReactElement<SearchBoxProps> => (
  <div className={clsx('search-box', isSearchWithNavigation && '-no-pad', className)}>
    <input
      className="search-box__input"
      placeholder={placeholderText}
      type="text"
      value={value}
      onChange={onChange}
    />
    <button className="search-box__button" type="button">
      <SearchIcon className="search-box__icon" />
    </button>
  </div>
);

export default SearchBox;
