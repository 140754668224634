import { ReactElement, SVGProps } from 'react';

type CheckboxIconProps = SVGProps<SVGSVGElement>;

const CheckboxIcon = (props: CheckboxIconProps): ReactElement<CheckboxIconProps> => (
  <svg height={16} width={16} {...props}>
    <rect
      fill="none"
      fillRule="evenodd"
      height={15}
      rx={2}
      stroke="#000"
      transform="translate(0 -1)"
      width={15}
      x={0.5}
      y={1.5}
    />
  </svg>
);

export default CheckboxIcon;
