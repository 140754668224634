import type { RefinementListProvided } from 'react-instantsearch-core';
import { connectRefinementList } from 'react-instantsearch-dom';

import { useTracking } from '~/lib/analytics';

import { sortAlphabeticallyWithFirstItems } from '../helpers';
import MultiSelectWidget from '../molocules/MultiSelectWidget';

type ConnectedRefinementListExposedProps = {
  attribute: string;
  capitalize?: boolean;
  label: string;
  placeholderText?: string;
};

const ConnectedRefinementList = connectRefinementList<
  RefinementListProvided & ConnectedRefinementListExposedProps
>(({ items, refine, currentRefinement, label, placeholderText }) => {
  const track = useTracking();
  const sortedItems = sortAlphabeticallyWithFirstItems(items);

  if (items.length === 0) {
    return null;
  }

  return (
    <MultiSelectWidget
      currentRefinement={currentRefinement}
      items={sortedItems}
      label={label}
      placeholderText={placeholderText}
      refine={(value) => {
        track('Applied filter', label);
        refine(value);
      }}
    />
  );
});

export default ConnectedRefinementList;
