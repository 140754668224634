import clsx from 'clsx';
import { useCombobox, useMultipleSelection } from 'downshift';
import uniqueId from 'lodash/uniqueId';
import { ReactElement, useMemo, useState } from 'react';

import ChevronIcon from '~/components/icons/ChevronIcon';
import CloseIcon from '~/components/icons/CloseIcon';

export type ItemType = {
  isRefined: boolean;
  label: string;
  value: string[];
};

type MultiSelectWidgetProps = {
  currentRefinement: string[];
  items: ItemType[];
  label: string;
  name?: string;
  placeholderText?: string;
  refine: (value: string[]) => void;
};

const MultiSelectWidget = ({
  label,
  refine,
  items = [],
  placeholderText,
}: MultiSelectWidgetProps): ReactElement<MultiSelectWidgetProps> => {
  const widgetId = uniqueId('multi-select-item::');

  const [inputValue, setInputValue] = useState('');

  const selectedItems = items.filter((item) => item.isRefined);

  const filteredItems = useMemo(
    () =>
      items.filter(
        (item) =>
          selectedItems.indexOf(item) < 0 &&
          item.label.toLowerCase().startsWith(inputValue.toLowerCase()),
      ),
    [selectedItems, inputValue, items],
  );

  const { getSelectedItemProps, getDropdownProps } = useMultipleSelection<ItemType>({
    onStateChange: ({ type }) => {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          if (selectedItems.length > 0) {
            refine(selectedItems[selectedItems.length - 1].value);
          }
          break;
        default:
          break;
      }
    },
    selectedItems,
  });

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    reset,
  } = useCombobox({
    inputValue,
    itemToString: (item) => item?.label ?? '',
    items: filteredItems,
    onStateChange: ({ inputValue: stateInputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(stateInputValue ?? '');
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            refine(selectedItem.value);
            reset();
          }
          break;
        default:
          break;
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          return {
            ...changes,
            isOpen: state.isOpen,
          };
        default:
          return changes;
      }
    },
  });

  return (
    <div className="form__field multi-select">
      <label htmlFor={widgetId} {...getLabelProps()} className="-b">
        {label}
      </label>
      <div
        {...getToggleButtonProps()}
        {...getComboboxProps()}
        aria-label="toggle menu"
        className="multi-select__main"
        id={widgetId}
      >
        {selectedItems.map((selectedItem, index) => (
          <span
            key={`selected-item::${selectedItem.label}`}
            className="multi-select__selected-item"
            {...getSelectedItemProps({
              index,
              onClick: () => {
                refine(selectedItem.value);
              },
              selectedItem,
            })}
          >
            {selectedItem.label} <CloseIcon />
          </span>
        ))}
        <input
          className={clsx('multi-select__input', {
            'multi-select__input--no-items': selectedItems.length === 0,
          })}
          placeholder={selectedItems.length === 0 ? placeholderText : null}
          {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
        />
        <div className="multi-select__button">
          <ChevronIcon />
        </div>
      </div>
      <ul {...getMenuProps()} className={clsx('multi-select__list', isOpen && '-open')}>
        {isOpen &&
          filteredItems.map((item, index) => (
            <li
              key={item.label}
              className={clsx(
                'multi-select__list-item',
                highlightedIndex === index && '-highlighted',
              )}
              {...getItemProps({ index, item })}
            >
              {item.label}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default MultiSelectWidget;
