import { ReactElement } from 'react';
import { FieldError, FieldValues,Path, RegisterOptions, UseFormRegister } from 'react-hook-form';

import FormField from './FormField';

export type InputProps<IValues extends FieldValues = FieldValues> = {
  className?: string;
  error?: FieldError;
  isDisabled?: boolean;
  label: string;
  name: Path<IValues>;
  placeholder?: string;
  register: UseFormRegister<IValues>;
  type?: string;
  validation?: RegisterOptions<IValues>;
};

const Input = <IValues extends FieldValues = FieldValues>({
  label,
  register,
  name,
  error,
  validation = {},
  isDisabled = false,
  type = 'text',
  placeholder,
  className,
}: InputProps<IValues>): ReactElement<InputProps<IValues>> => (
  <FormField
    className={className}
    error={error}
    isDisabled={isDisabled}
    isRequired={!!validation.required}
    label={label}
    name={name}
  >
    <input
      aria-errormessage={`${name}-error`}
      aria-invalid={!!error?.type}
      autoComplete="none"
      disabled={isDisabled}
      id={name}
      placeholder={placeholder}
      type={type}
      {...register(name, validation)}
    />
  </FormField>
);

export default Input;
