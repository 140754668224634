import { PropsWithChildren, ReactElement } from 'react';

type ErrorMessageProps = PropsWithChildren<{
  name: string;
}>;

const ErrorMessage = ({ children, name }: ErrorMessageProps): ReactElement => (
  <div
    aria-labelledby={`${name}-label`}
    className="form__field-error"
    id={`${name}-error`}
    role="alert"
  >
    {children}
  </div>
);

export default ErrorMessage;
