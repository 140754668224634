import { ReactElement, useEffect } from 'react';

import PaginationButton, { PaginationButtonType } from '~/components/shared/atoms/PaginationButton';

type PaginationProps = {
  currentPage: number;
  handleDecrease: () => void;
  handleIncrease: () => void;
  totalPages: number;
};

const Pagination = ({
  handleDecrease,
  handleIncrease,
  currentPage,
  totalPages,
}: PaginationProps): ReactElement<PaginationProps> => {
  const isBackwardDisabled = currentPage === 1;
  const isForwardDisabled = currentPage >= totalPages;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <div className="pagination">
      <div className="pagination__content">
        <PaginationButton
          isDisabled={isBackwardDisabled}
          type={PaginationButtonType.Backward}
          onClick={handleDecrease}
        />
        <PaginationButton
          isDisabled={isForwardDisabled}
          type={PaginationButtonType.Forward}
          onClick={handleIncrease}
        />
      </div>
    </div>
  );
};

export default Pagination;
