import styled, { css, FlattenInterpolation } from 'styled-components';

import titleThreeMixin from '~/style-mixins/typography/titleThreeMixin';

export interface ButtonBaseProps {
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary' | 'secondary-alt' | 'brand' | 'disabled' | 'red';
}

const disabledStyleMixin = css`
  background-color: var(--c-dark-silver);
  color: var(--c-darkgrey);
  cursor: not-allowed;

  &:hover {
    filter: brightness(0.9);
    box-shadow: none;
  }
`;

// https://projects.invisionapp.com/d/main?#/console/20518969/438962865/inspect
const ButtonBase = styled.button<ButtonBaseProps>`
  font-weight: 500;
  border-radius: var(--s-bw);
  transition: box-shadow 0.25s ease, background-color 0.5s ease;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  position: relative;
  font-weight: 600;

  svg,
  path,
  polygon {
    fill: currentColor;
  }

  // Increase the specificity of the generated class so that global 'a' styles do
  // not override when using as="a".
  &&& {
    ${({ size = 'medium', variant = 'primary' }): FlattenInterpolation<ButtonBaseProps> => css`
      &:disabled {
        ${disabledStyleMixin}
      }

      ${size === 'small' &&
      css`
        padding: 0.25rem 0.5rem;
        font-size: var(--fs-12);
        line-height: var(--fs-16);

        svg {
          font-size: var(--fs-16);
          margin-right: 0.25rem;
        }
      `}
      ${size === 'medium' &&
      css`
        padding: 0.5rem 1rem;
        font-size: var(--fs-xs);
        line-height: var(--fs-24);

        svg {
          font-size: var(--fs-24);
          margin-right: 0.5rem;
        }
      `}
    ${size === 'large' &&
      css`
        ${titleThreeMixin}
        padding: 0.75rem 2rem;
        line-height: var(--fs-24);

        svg {
          font-size: var(--fs-24);
          margin-right: 0.5rem;
        }
      `}

  ${variant === 'disabled' && disabledStyleMixin}    
  ${variant === 'primary' &&
      css`
        background-color: var(--c-black);
        color: var(--c-white);

        &:hover {
          box-shadow: var(--c-brand-primary) 0 0 0 2px;
        }

        ${size === 'large' &&
        css`
          &:hover {
            background-color: var(--c-brand-primary);

            &:disabled {
              ${disabledStyleMixin}
            }
          }
        `}
      `}
  ${variant === 'secondary' &&
      css`
        background-color: var(--c-white);
        color: var(--c-black);
        box-shadow: var(--c-light-silver) 0 0 0 1px;

        &:hover {
          box-shadow: var(--c-brand-primary) 0 0 0 2px;
        }
      `}
      ${variant === 'red' &&
      css`
        color: var(--c-white);
        background-color: var(--c-red);
        box-shadow: var(--c-red) 0 0 0 1px;

        &:hover {
          box-shadow: var(--c-red) 0 0 0 2px;
        }
      `}
    ${variant === 'secondary-alt' &&
      css`
        background-color: var(--c-white);
        color: var(--c-black);
        box-shadow: var(--c-light-silver) 0 0 0 1px;

        svg,
        path,
        polygon {
          fill: var(--c-brand-primary);
        }

        &:hover {
          background-color: var(--c-offwhite);
          box-shadow: var(--c-brand-primary) 0 0 0 2px;
        }
      `}
    ${variant === 'brand' &&
      css`
        background-color: var(--c-brand-primary);
        color: var(--c-white);

        &:hover {
          filter: brightness(0.9);
        }
      `}
    `}
  }
`;

export default ButtonBase;
