import clsx from 'clsx';
import type { ComponentClass, FC, ReactElement, ReactNode } from 'react';
import { connectHits, connectPagination, connectStateResults } from 'react-instantsearch-dom';
import { Asset } from '~/lib/assets';
import defaultEmptySearchImageUrl from '~/../assets/images/illustrations/empty-search.svg';

import Pagination from '../atoms/Pagination';
import SearchBar from '../organisms/SearchBar';

const ConnectedPagination = connectPagination(
  ({
    currentRefinement,
    nbPages,
    refine,
  }: {
    currentRefinement: number;
    nbPages: number;
    refine: (refinement: number) => void;
  }) => {
    if (nbPages <= 1) return null;

    return (
      <Pagination
        currentPage={currentRefinement}
        handleDecrease={() => refine(currentRefinement - 1)}
        handleIncrease={() => refine(currentRefinement + 1)}
        totalPages={nbPages}
      />
    );
  },
);

type SearchTemplateProps = {
  children?: ReactNode;
  filters?: () => ReactNode;
  isSearchWithNavigation?: boolean;
  navBar?: () => ReactNode;
  searchBarMinimized?: boolean;
  searchBoxPlaceholder?: string;
  searchHeader?: string;
};

const SearchTemplate = ({
  searchBoxPlaceholder,
  navBar = () => null,
  filters,
  children,
  searchBarMinimized = false,
  isSearchWithNavigation,
  searchHeader,
}: SearchTemplateProps): ReactElement<SearchTemplateProps> => (
  <>
    <header
      className={clsx(
        'page-header',
        isSearchWithNavigation ? 'search-navigation' : 'search-header',
        searchBarMinimized && '-search-minimised',
      )}
    >
      {navBar()}
      <nav className="page-header__index">
        {searchHeader && <h2 className="-b">{searchHeader}</h2>}
        <SearchBar
          filters={filters}
          isSearchWithNavigation={isSearchWithNavigation ?? false}
          placeholderText={searchBoxPlaceholder}
        />
      </nav>
    </header>
    <section className="search">{children}</section>
    <ConnectedPagination />
  </>
);

const emptySearchImageUrl = Asset.emptySearchResultsImage || defaultEmptySearchImageUrl;

export const connectHitsWithPlaceholder = <T,>(
  Component: FC<{ hits: T[] }>,
): ComponentClass<Record<string, unknown>, unknown> =>
  connectHits<T>(({ hits }) => {
    const ComponentWithState = connectStateResults(({ searching }: { searching?: boolean }) => {
      if ((hits?.length || 0) < 1 && !searching) {
        return (
          <div className="search-no-results">
            <img alt="Sorry, no results found!" src={emptySearchImageUrl} />
            <div>Sorry, no results found.</div>
            <div>Try adjusting your search or filters to find what you’re looking for.</div>
          </div>
        );
      }

      return <Component hits={hits} />;
    });

    return <ComponentWithState />;
  });

export default SearchTemplate;
