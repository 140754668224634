declare global {
  interface Window {
    config?: Partial<Config>;
  }
}

export type Config = {
  avengerUrl: string;
  calendarUrl: string;
  catalystUrl: string;
  rsvpUrl: string;
  wsloginUrl: string;
};

const globalConfig: Config = {
  avengerUrl: window.config?.avengerUrl || 'https://api.cilabs.com',
  calendarUrl: window.config?.calendarUrl || 'https://calendar.cilabs.com',
  catalystUrl: window.config?.catalystUrl || 'https://graphql.cilabs.com/graphql',
  rsvpUrl: window.config?.rsvpUrl || 'https://api.cilabs.com/campaigns/api',
  wsloginUrl: window.config?.wsloginUrl || 'wss://wslogin.cilabs.com',
};

export default globalConfig;
