import { ReactElement, SVGProps } from 'react';

type CheckedCheckboxIconProps = SVGProps<SVGSVGElement>;

const CheckedCheckboxIcon = (
  props: CheckedCheckboxIconProps,
): ReactElement<CheckedCheckboxIconProps> => (
  <svg height={16} width={16} {...props}>
    <path
      d="M14 0a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h12zm-.94 3.727L6 10.78 3.22 8l-.947.94L6 12.667l8-8-.94-.94z"
      fillRule="evenodd"
    />
  </svg>
);

export default CheckedCheckboxIcon;
