import { ReactElement,ReactNode } from 'react';

const CheckboxControl = ({ children }: { children: ReactNode }): ReactElement => (
  <div className="checkbox-control">{children}</div>
);

CheckboxControl.Label = ({ children }: { children: ReactNode }): ReactElement => (
  // Get rid of this when you refactor or change to styled-components
  // eslint-disable-next-line jsx-a11y/label-has-for
  <label className="checkbox-control__label">{children}</label>
);

CheckboxControl.Description = ({ children }: { children: ReactNode }): ReactElement => (
  <div className="checkbox-control__description">{children}</div>
);

export default CheckboxControl;
