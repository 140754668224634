import { ReactElement, SVGProps } from 'react';

type CloseIconProps = SVGProps<SVGSVGElement>;

const CloseIcon = (props: CloseIconProps): ReactElement<CloseIconProps> => (
  <svg height={24} viewBox="0 0 24 24" width={24} {...props}>
    <polygon points="18 16.59 16.59 18 12 13.41 7.41 18 6 16.59 10.59 12 6 7.41 7.41 6 12 10.59 16.59 6 18 7.41 13.41 12" />
  </svg>
);

export default CloseIcon;
