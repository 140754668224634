import clsx from 'clsx';
import { useCombobox } from 'downshift';
import { ReactElement, useMemo, useState } from 'react';

import ChevronIcon from '~/components/icons/ChevronIcon';

export type OptionType = {
  id: string;
  name: string;
};

type DropdownProps = {
  label?: string;
  name?: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  options: OptionType[];
  placeholder?: string;
  value: string;
};

const Dropdown = ({
  onBlur,
  onChange,
  name,
  options = [],
  value,
  label,
  placeholder,
}: DropdownProps): ReactElement<DropdownProps> => {
  const [inputItems, setInputItems] = useState(options);

  const selectedItem = useMemo(() => options?.find(({ id }) => id === value), [options, value]);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
  } = useCombobox({
    id: name,
    itemToString: (item) => item?.name ?? '',
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      if (typeof inputValue === 'string') {
        setInputItems(
          options.filter((item) => item.name.toLowerCase().startsWith(inputValue?.toLowerCase())),
        );
      }
    },
    onIsOpenChange: ({ isOpen: newIsOpen }) => {
      if (newIsOpen) {
        setInputItems(options);
      }
    },
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      onChange(newSelectedItem?.id ?? '');
    },
    selectedItem: selectedItem || null,
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.InputBlur:
          if (inputItems.length === 1) {
            return {
              ...changes,
              inputValue: inputItems[0].name,
              selectedItem: inputItems[0],
            };
          }

          return {
            ...changes,
            inputValue: changes.selectedItem?.name ?? '',
          };
        default:
          return changes;
      }
    },
  });

  return (
    <div className="dropdown">
      <div {...getComboboxProps()} className="dropdown__main">
        <input
          {...getInputProps({
            name,
            onBlur,
            onFocus: () => {
              if (!isOpen) {
                openMenu();
              }
            },
          })}
          aria-label={label}
          className="dropdown__input"
          placeholder={placeholder}
        />
        <button
          className="dropdown__button"
          type="button"
          {...getToggleButtonProps()}
          aria-label="toggle menu"
        >
          <ChevronIcon />
        </button>
      </div>
      <ul {...getMenuProps()} className={clsx('dropdown__list', isOpen && '-open')}>
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              key={item.id}
              className={clsx('dropdown__list-item', highlightedIndex === index && '-highlighted')}
              {...getItemProps({ index, item })}
            >
              {item.name}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Dropdown;
