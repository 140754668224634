import { createContext, ReactElement, ReactNode, SetStateAction,useMemo,useState } from 'react';

type NewMessagesCounterType = {
  newMessagesCounter: number;
  setNewMessagesCounter: (n: SetStateAction<number>) => void;
};

export const NewMessagesCounterContext = createContext<NewMessagesCounterType>({
  newMessagesCounter: 0,
  setNewMessagesCounter: () => {
    // Default no-op
  },
});

type NewMessagesCounterProps = {
  children?: ReactNode;
};

const NewMessagesCounter = ({
  children,
}: NewMessagesCounterProps): ReactElement<NewMessagesCounterProps> => {
  const [newMessagesCounter, setNewMessagesCounter] = useState(0);

  const newMessagesValue = useMemo(() => ({
    newMessagesCounter,
    setNewMessagesCounter
  }), [newMessagesCounter])

  return (
    <NewMessagesCounterContext.Provider value={newMessagesValue}>
      {children}
    </NewMessagesCounterContext.Provider>
  );
};

export default NewMessagesCounter;
