import currentSlug from './currentSlug';

let cookies: { [key: string]: string };

const readCookieUnscoped = (name: string) => {
  if (cookies) {
    return cookies[name];
  }

  const cookie = document.cookie.split('; ');
  cookies = {};

   
  for (let i = cookie.length - 1; i >= 0; i--) {
    const [key, val] = cookie[i].split('=');
    cookies[key] = val;
  }

  return cookies[name];
};

const readCookie = (name: string): string => {
  const slug = currentSlug();
  return readCookieUnscoped([slug, name].join('_'));
};

export default readCookie;
